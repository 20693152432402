@import "../../theme/colors";
@import "../../theme/fonts";

#Menu {
  height: 100vh;
  width: 100vw;
  transition: opacity 2s 0s ease;
  position: relative;

  .menuBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6.25rem 1.25rem;

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    .menu {
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      .title {
        color: $accent;
        font-family: $SFBoldFont;
        font-size: 2.5rem;
      }

      .hint {
        font-family: $SFBoldFont;
        font-size: 1.375rem;
        margin: 1.25rem 0;
      }

      .description {
        font-family: $SFTextSemiBoldFont;
        font-size: 1rem;
        margin: 3.25rem 0;
      }
    }

    .buttonBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
  }
}
