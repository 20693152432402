@import "../../theme/colors";
@import "../../theme/fonts";

.userCard {
  display: flex;
  flex-direction: row;
  margin: 0.75rem 1.25rem;
  align-items: center;
  position: relative;

  .userRank {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 2.5rem;
  }
  .rankingPP {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    margin-right: 1rem;
    object-fit: cover;
  }

  .rankingName {
    font-family: $SFBoldFont;
    font-size: 1rem;
    color: black;
    width: 43%;
    overflow: hidden;
    white-space: wrap;
  }

  .rankingPoints {
    position: absolute;
    right: 0;
  }
}
