@import "../../theme/colors";
@import "../../theme/fonts";

#EndPage {
  .endPageBody {
    .endPageContent {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      text-align: center;
      padding: 5.5rem 1.5rem;

      .profilePicture {
        display: flex;
        position: relative;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .blason {
          width: 12.5rem;
          height: 11.3rem;
          color: $accent;
        }

        .picture {
          position: absolute;
          width: 4.8rem;
          height: 4.8rem;
          border-radius: 2.9rem;
          object-fit: cover;
          margin: auto auto;
        }
      }

      .endTitle {
        font-size: 2.2rem;
        font-family: $SFBoldFont;
        color: $accent;
        margin: 0.625rem 0;
      }

      .endScore {
        font-size: 1.25rem;
        font-family: $SFBoldFont;
        color: $accent;
      }

      .endRanking {
        font-size: 1rem;
        font-family: $SFBoldFont;
        color: black;
        margin-top: 1rem;
      }

      .button {
        padding: 0.5rem 2rem;
        border-radius: 2rem;
        background-color: $accent;
        color: #fff;
        font-size: 1.1rem;
        font-family: $SFSemiboldFont;
        margin: 1.87rem 0;
      }
    }
  }
}
