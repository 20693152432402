@import "../../theme/colors";
@import "../../theme/fonts";

.button {
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  background-color: $accent;
  font-family: $SFSemiboldFont;
  color: #fff;
  font-size: 1rem;
  font-size: 1.125rem;
  margin: 0.625rem 0;
  width: 13.8rem;
  height: 2.6rem;
  text-align: center;
  box-shadow: 0 0.625rem 1.25rem 0.063rem rgba(148, 18, 38, 0.2);
}
