@import "../../../../theme/colors";
@import "../../../../theme/fonts";

#instructionsSlideContent {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 1.25rem;
  padding-bottom: 4rem;
  height: 100%;
  width: 100%;

  .slideImg {
    position: relative;
    width: 90vw;

    .fruitVisual {
      min-width: 0;
      min-height: 0;
    }
    .chevronIconLeft,
    .chevronIconRight {
      min-height: 2rem;
      max-height: 2rem;
      height: 2rem;
      min-width: 2rem;
      max-width: 2rem;
      width: 2rem;
      position: absolute;
      margin: auto;
    }
  }

  .instructions {
    font-family: $SFTextRegularFont;
    font-size: 1.25rem;
  }

  .chevronIconLeft {
    top: 0;
    left: 0;
    bottom: 0;
    margin: 50% 1rem;
  }

  .chevronIconRight {
    top: 0;
    right: 0;
    bottom: 0;
    margin: 50% 1rem;
  }
}
