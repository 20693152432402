@import "../../theme/colors";
@import "../../theme/fonts";

.gameHeader {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  z-index: 10000;
  margin-top: env(safe-area-inset-top);

  .points {
    color: black;
    display: flex;
    align-self: flex-start;
    margin: 1.25rem;
    font-family: $SFBoldFont;
    font-size: 2rem;
    align-items: center;

    span {
      font-size: 1.5rem;
    }
  }

  .timerAndClose {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    align-self: left;

    .timer {
      color: black;
      width: 90px;
      margin: 1.25rem 0;
      font-family: $SFBoldFont;
      font-size: 2rem;
      text-align: left;

      span {
        font-size: 1.5rem;
        text-align: left;
      }
    }
  }
}
