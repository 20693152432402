.closeButton {
  border-radius: 1.063rem;
  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;

  .closeIcon {
    color: white;
  }
}
