$font-path: "/assets/fonts/";

@mixin font-face($font-family) {
  @font-face {
    font-family: "#{$font-family}";
    src: url("#{$font-path}#{$font-family}.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
  }
}

$SFBlackFont: "SF-Pro-Display-Black";
@include font-face($SFBlackFont);

$SFRegularFont: "SF-Pro-Display-Regular";
@include font-face($SFRegularFont);

$SFSemiboldFont: "SF-Pro-Display-Semibold";
@include font-face($SFSemiboldFont);

$SFBoldFont: "SF-Pro-Display-Bold";
@include font-face($SFBoldFont);

$SFBoldItalicFont: "SF-Pro-Display-BoldItalic";
@include font-face($SFBoldItalicFont);

$SFHeavyFont: "SF-Pro-Display-Heavy";
@include font-face($SFHeavyFont);

$SFHeavyItalicFont: "SF-Pro-Display-HeavyItalic";
@include font-face($SFHeavyItalicFont);

$SFLightFont: "SF-Pro-Display-Light";
@include font-face($SFLightFont);

$SFLightItalicFont: "SF-Pro-Display-LightItalic";
@include font-face($SFLightItalicFont);

$SFMediumFont: "SF-Pro-Display-Medium";
@include font-face($SFMediumFont);

$SFMediumItalicFont: "SF-Pro-Display-MediumItalic";
@include font-face($SFMediumItalicFont);

$SFTextSemiBoldFont: "SF-Pro-Text-Semibold";
@include font-face($SFTextSemiBoldFont);

$SFTextRegularFont: "SF-Pro-Text-Regular";
@include font-face($SFTextRegularFont);
