@import "../../theme/colors";
@import "../../theme/fonts";

#instructionPage {
  background-image: url("../../assets/img/Background.png");
  padding-top: max(1.25rem, env(safe-area-inset-top));
  padding-bottom: 1.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 2s 0s ease;

  .instructionBody {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;

    .instructionTitle {
      font-family: $SFBoldFont;
      font-size: 1.5rem;
    }

    .instructionTitle::first-letter {
      text-transform: capitalize;
    }

    #instructionSwiper {
      width: 100vw;
      height: 70vh;

      .instructionSlides {
        width: 100%;
        height: 100%;
      }
    }
  }
}
