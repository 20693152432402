$largeSize: 7.5rem;
$smallSize: 5rem;

.gameLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gameLoader {
  &-small {
    width: $smallSize;
    height: $smallSize;
    & div {
      animation: gameLoader-small 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  &-large {
    width: $largeSize;
    height: $largeSize;
    & div {
      animation: gameLoader-large 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  &-large,
  &-small {
    display: inline-block;
    position: relative;
    & div {
      position: absolute;
      border: 4px solid #ababab;
      opacity: 1;
      border-radius: 50%;

      &:nth-child(2) {
        animation-delay: -0.5s;
      }
    }
  }
}

@keyframes gameLoader-small {
  0% {
    top: calc(#{$smallSize}/ 2);
    left: calc(#{$smallSize}/ 2);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: $smallSize;
    height: $smallSize;
    opacity: 0;
  }
}

@keyframes gameLoader-large {
  0% {
    top: calc(#{$largeSize}/ 2);
    left: calc(#{$largeSize}/ 2);
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: $largeSize;
    height: $largeSize;
    opacity: 0;
  }
}
