@import "../../theme/colors";
@import "../../theme/fonts";

#questionPage {
  transition: opacity 2s 0s ease;
  position: relative;

  .questionBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    margin-top: env(safe-area-inset-top);

    .pagination {
      font-family: $SFBoldFont;
      font-size: 1.5rem;
    }

    .questionTitle {
      font-family: $SFBoldFont;
      font-size: 1.75rem;
      padding: 0 1.25rem;
    }

    .coverImg {
      height: 50%;
      width: 100%;
    }

    .playButton {
      padding: 0.5rem 2rem;
      border-radius: 2rem;
      background-color: $accent;
      font-family: $SFSemiboldFont;
      color: #fff;
      font-size: 1rem;
      font-size: 1.125rem;
      margin: 5.25rem 0;
      width: 13.8rem;
      height: 2.6rem;
      text-align: center;
      box-shadow: 0 0.625rem 1.25rem 0.063rem rgba(148, 18, 38, 0.2);
    }
  }
}
