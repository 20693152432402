@import "../../../../theme/colors";
@import "../../../../theme/fonts";

ion-modal {
  --border-radius: 0.625rem 0.625rem 0 0;
  --box-shadow: 0 -0.188rem 1.25rem 0rem rgba(0, 0, 0, 0.1);

  &::part(handle) {
    width: 3.12rem;
    margin-top: 0.938rem;
    z-index: 1000000;
  }

  &::part(backdrop) {
    pointer-events: none;
  }

  ion-content {
    --padding-top: 2.18rem;
    --padding-bottom: 2.18rem;
    --padding-end: 1.25rem;
    --padding-start: 1.25rem;
    --color: #c90322;
    font-size: 1.25rem;
    font-family: $SFBoldFont;
  }
}
