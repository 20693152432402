.background {
  position: absolute;
  z-index: -1;

  .backgroundImg,
  .backgroundBlur {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    z-index: 1;
  }

  .backgroundBlur {
    position: absolute;
  }
}
