@import "../../theme/colors";
@import "../../theme/fonts";

#Game {
  transition: opacity 2s 0s ease;
  background-size: cover;
  background-position: center;

  .timerPointOut {
    opacity: 0;
    transition:
      width 0.5s 0.5s,
      height 0.5s 0.5s,
      opacity 0.5s;
  }

  .timerPointIn {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0.7;
    transition:
      width 0.5s,
      height 0.5s,
      opacity 0.5s 0.5s;

    img {
      height: 40px;
      width: 35px;
      margin: 5px;
    }

    .timerPointHint {
      font-family: $SFBoldFont;
      font-size: 20px;
    }
  }
}

#Fruits {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
}

canvas {
  height: 100vh;
  width: 100vw;
}
