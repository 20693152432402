.page {
  height: 100vh;
  width: 100vw;
  // padding-top: env(safe-area-inset-top);
}

.title {
  font-size: 2rem;
  color: #000;
  font-weight: bold;
}

.description {
  font-size: 1.2rem;
  color: #000;
}
